//import { VideoFileDoubleBufferedResource } from "./resources/VideoFileDoubleBufferedResource.js";
import { VideoFileResource } from "./resources/VideoFileResource.js";
//import { VideoFileSingleBufferedResource } from "./resources/VideoFileSingleBufferedResource.js";
import { WebApplication } from "../../webApplication.js";
import { ImageResource } from "./resources/ImageResource.js";
import { AudioVisualScriptResource } from "../audio-visual-script/AudioVisualScriptResource.js";
import { VisualElement } from "./visualElement.js";
import { MediaSourceResourceResolver } from "./resources/MediaSourceResourceResolver.js";
import { SceneGraphNode } from "../../sceneGraph/scene_graph_node.js";
import { UpdateContext } from "../../update.js";
import { ResourceTypeInterface } from "./resources/resourceInterface";
import { MeidaSourcePlayerComponent } from "./MediaSourcePlayerComponent";

export class VisualElements {
    static DisplayTimeoutMS = 4000;
    static ImageVisualResourceType: any;
    static VideoVisualResourceType: any;
    static AudioVisualScriptVisualResourceType: any;
    static visualResourceTypes: any[];

    static initializeResourceTypes(application: WebApplication) {
        VisualElements.visualResourceTypes = [];

        VisualElements.ImageVisualResourceType = ImageResource;
        VisualElements.visualResourceTypes.push(VisualElements.ImageVisualResourceType);

        if (application.getSetting(WebApplication.IsVideoEnabledSettingName)) {
            if (
                application.getSetting(WebApplication.IsMSVideoEnabledSettingName) &&
                MeidaSourcePlayerComponent.isSupportedByPlatform()
            ) {
                console.log(`new MSE video visual element`);
                VisualElements.VideoVisualResourceType = MediaSourceResourceResolver;
                // VisualElements.VideoVisualResourceType = VideoFileResource;
            } /*else if (application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
                // console.log(`new db video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileDoubleBufferedResource;
            } else if (application.getSetting(WebApplication.IsSBVideoEnabledSettingName)) {
                //  console.log(`new sb video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileSingleBufferedResource;
            }*/ else {
                //  console.log(`new ps video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileResource;
            }
            VisualElements.visualResourceTypes.push(VisualElements.VideoVisualResourceType);
            //console.log("can create", VisualElements.visualResourceTypes);
        }

        if (application.getSetting(WebApplication.isAudioVisualScriptsEnabled_SettingName)) {
            VisualElements.AudioVisualScriptVisualResourceType = AudioVisualScriptResource;
            VisualElements.visualResourceTypes.push(
                VisualElements.AudioVisualScriptVisualResourceType,
            );
        }
    }

    // visual_element_display_timeouts: any = {};
    visual_element_display_timeouts = new Map<VisualElement, NodeJS.Timeout>();
    visual_elements: VisualElement[] = [];
    obj: SceneGraphNode;

    constructor(obj: SceneGraphNode) {
        this.obj = obj;
    }
    get server_file_cache() {
        return this.obj.server_file_cache;
    }

    isAdded(e: VisualElement) {
        const index = this.visual_elements.indexOf(e);
        return index > -1;
    }

    add(e: VisualElement) {
        this.visual_elements.push(e);
        return e;
    }

    remove(e: VisualElement) {
        this.cancelDisplayTimeout(e);
        const index = this.visual_elements.indexOf(e);
        if (index > -1) {
            this.visual_elements.splice(index, 1);
        }
    }

    onDisplayTimeout(e: VisualElement) {
        this.cancelDisplayTimeout(e);
        console.info(`visual:timeout `);
        e.on_display_timeout();
    }

    startDisplayTimeout(e: VisualElement) {
        this.cancelDisplayTimeout(e);
        //   console.assert(this.visual_element_display_timeouts[e] == undefined);

        let timer = setTimeout(() => {
            this.onDisplayTimeout(e);
        }, VisualElements.DisplayTimeoutMS);
        // console.info(`visual:start:timeout `);

        // this.visual_element_display_timeouts[e] = timer;
        this.visual_element_display_timeouts.set(e, timer);
    }

    cancelDisplayTimeout(e: VisualElement) {
        // var timer = this.visual_element_display_timeouts[e];
        let timer = this.visual_element_display_timeouts.get(e);
        //console.assert(timer != undefined);
        if (timer) {
            // console.info(`visual:stop:timeout `);
            clearTimeout(timer);
            // delete this.visual_element_display_timeouts[e];
            this.visual_element_display_timeouts.delete(e);
        }
    }

    update(update_context: UpdateContext) {
        for (const each of this.visual_elements) {
            each.update(update_context);
        }
    }
}
