import { WebApplication } from "../../../webApplication.js";
import { ResourceCanvasElementInterface } from "../resourceCanvasElements/ResourceCanvasElementInterface.js";
import { ImageResource } from "../resources/ImageResource";
import { ResourceInterface } from "../resources/resourceInterface";
import { VideoFileResource } from "../resources/VideoFileResource";
import { HTML5CanvasElement } from "./HTML5CanvasElement.js";
import { VideoDisplayToElement } from "./VideoDisplayToElement";
import { VideoFileToCanvasElement } from "./VideoFileToCanvasElement.js";
import { VideoFileToVideoElement } from "./VideoFileToVideoElement.js";

export class VideoFileToElement extends HTML5CanvasElement {
    static isEnabled(webapp: WebApplication): boolean {
        return webapp.getSetting(WebApplication.IsVideoEnabledSettingName);
    }
    static isDrawToCanvasEnabled(webapp: WebApplication) {
        return webapp.getSetting(WebApplication.IsAllCanvasVideosSettingName);
    }
    video_display?: VideoDisplayToElement;

    state = { isPlaying: false };
    playing: Promise<any>;
    constructor(
        resource: ResourceInterface,
        element: HTMLVideoElement,
        resource_canvas_element?: ResourceCanvasElementInterface,
        is_invalidating_draw = true,
    ) {
        super(resource, element, resource_canvas_element, is_invalidating_draw);
    }
    get resource_video() {
        let r = this.resource as VideoFileResource;
        return r;
    }

    get element_video() {
        let r = this.element as HTMLVideoElement;
        return r;
    }

    onStartedError(event: Event | string) {
        // console.warn("video:element:start:error")

        this.addError(`video:element:start:error ${event}` + "\n");
    }

    addingToInteractiveCanvas() {
        if (!this.icanvas) {
            return;
        }

        let isEnabled = VideoFileToElement.isEnabled(this.icanvas.State.application);

        if (!isEnabled) {
            return this.addError("video not enabled");
        }

        this.element_video.onerror = this.onStartedError.bind(this);

        if (this.isFirstTimeCanvasAdd) {
            if (this.resource_video?.isAutoPlay) {
                this.playVideo();
            }
        } else if (this.element_video.paused && this.state.isPlaying) {
            this.playVideo();
        }

        let isCanvasDisplayEnabled = VideoFileToElement.isDrawToCanvasEnabled(
            this.icanvas.State.application,
        );

        if (isCanvasDisplayEnabled) {
            this.video_display = new VideoFileToCanvasElement(this);
        } else {
            this.video_display = new VideoFileToVideoElement(this);
        }
        this.video_display.addedToInteractiveCanvas();
    }

    removeingFromInteractiveCanvas() {
        this.video_display?.removedFromInteractiveCanvas();

        this.element_video.onerror = null;

        if (!this.element_video.paused) {
            this.element_video.pause();
        }

        this.video_display = undefined;
    }

    playVideo() {
        console.error("VIDEOFILEtoElement:playVideo called()");
        if (!this.element_video.paused) {
            return;
        }

        this.state.isPlaying = true;

        console.info("video:element:play");
        console.error("PLAY() should not be called");
        this.playing = this.element_video
            .play()
            .catch((err) => console.warn("warning: on play()", err));

        this.playing.catch((e) => {
            let pause_error = "The play() request was interrupted by a call to pause()";
            if (e.message.includes(pause_error)) {
                return;
            }
            this.addError("error:video:element:play" + this.toSourceURL() + "\n" + e);
        });
    }

    draw() {
        super.draw();
        this.video_display?.draw();
    }

    onCanvasResized() {
        super.onCanvasResized();
        this.video_display?.onCanvasResized?.();
    }

    validate() {
        super.validate();
        this.video_display?.validate?.();
    }
}
