import { WebApplicationState } from "../WebApplicationState";
import page from "page";
import { showPendingPage } from "./routes/authorization/page";
import { showTVLoginPage } from "./routes/login/page";
import { showLoadingPage } from "./routes/loading/page";

export class StaticRouter {
    state: WebApplicationState;

    constructor(state: WebApplicationState) {
        this.state = state;
    }

    /**
     * Initialize the router by defining all routes.
     */
    initialize() {
        // Base login page
        page("/login", () => {
            showTVLoginPage(this.state);
        });

        // User selected sign in on tv, waiting for mobile device to authenticate
        page("/authorize", () => {
            showPendingPage(this.state);
        });

        // The loading page for in between pages.
        page("/loading", () => {
            showLoadingPage(this.state);
        });

        page("/", () => {});
        page(); // Initialize all routes
    }

    /**
     * Redirects to the login page.
     */
    redirectToLogin() {
        page.redirect("/login");
    }

    /**
     * Resets the canvas to the original state.
     * @param id the id of the current page. Currently only use login-modal and remote-page.
     */
    resetPageToCanvas(id: string) {
        document.getElementById(id)?.remove();
        this.state.canvas.canvas.style.display = "block";
        const video = document.getElementById("video-");
        if (video) video.style.display = "block";
        this.state.canvas.enableInput();
    }

    /**
     * Used to load the content of a page into the viewport.
     * @param page the HTML content of the page to load.
     * @param id the id of the page to load.
     */
    addPageToViewport(page: string, id: string) {
        this.state.canvas.disableInput();
        this.state.canvas.canvas.style.display = "none";
        const video = document.getElementById("video-");
        if (video) video.style.display = "none";

        if (!document.getElementById(id)) {
            const container = document.createElement("div");
            container.id = id;
            container.innerHTML = page;
            this.state.canvas.viewport.appendChild(container);
        } else {
            const loginModal = document.getElementById(id);
            if (loginModal) loginModal.innerHTML = page;
        }
    }

    /**
     * TODO - what is this used for?
     */
    saveState() {
        console.log("Static router save state");
    }

    /**
     *
     */
    shutdown() {
        this.saveState();
    }
}
