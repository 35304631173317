import { AUTH_PAGE_TYPES, AUTH_PAGES } from "../../../auth/config";
import { WebApplicationState } from "../../../WebApplicationState";

/**
 * Shows the pending page, shown when the user is waiting for the phone to authenticate.
 * This is where the QR code is shown.
 * @param state the state of the application.
 */
export async function showPendingPage(state: WebApplicationState) {
    let redirectURL: string | undefined = undefined;
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("redirect")) {
        redirectURL = searchParams.get("redirect") || undefined;
    }

    // The redirect url is encoded, so we need to decode it.
    if (redirectURL) {
        redirectURL = decodeURIComponent(redirectURL);
    }

    state.auth.createLoginSession(redirectURL);
    state.router.addPageToViewport(AUTH_PAGES.LOGIN_PENDING, AUTH_PAGE_TYPES.LOGIN_MODAL);

    const cancelButton = document.getElementById("cancel-button");
    cancelButton?.focus();

    /**
     * Handles when the cancel button is clicked.
     * Returns to the login page.
     */
    const cancelHandler = () => {
        console.log("Cancel login session");
        window.location.href = `/login?redirect=${redirectURL}`;
        removeEventListeners();
    };

    /**
     * Removes the event listeners for the pending page.
     */
    function removeEventListeners() {
        cancelButton?.removeEventListener("click", cancelHandler);
        removeEventListener("keydown", cancelHandler);
    }

    cancelButton?.addEventListener("click", cancelHandler);
}
