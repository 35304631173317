import {
    GettingReadyCallback,
    GettingReadyCallbackCollector,
} from "../../sceneGraph/GettingReadyCallbackCollector";
import { SortedRangeAssociatedList } from "../../SortedRangeAssociatedList";
import { TimeUtils } from "../../TileUtils";
import { Range } from "../../Range";
import { UpdateContext } from "../../update";
import { Scene } from "../../sceneGraph/scene";
import { SceneObjectInterface } from "../../sceneGraph/SceneObjectInterface";
import { AudioVisualPlaybackQueueItem } from "./AudioVisualPlaybackQueueItem";
import { ResourceCanvasElementInterface } from "./resourceCanvasElements/ResourceCanvasElementInterface";

/**
 * a collection of sorted time ranges, each time range is associated with a AudioVisualPlaybackQueueItem
 * one AudioVisualPlaybackQueueItem is playing at a time
 * AudioVisualPlaybackQueueItems are played when they are sceduled to play depending on their associated time range
 */
export class AudioVisualPlaybackQueue {
    callback_collector_promises: Promise<any>[] = [];
    item_queue = new SortedRangeAssociatedList<AudioVisualPlaybackQueueItem>();
    scene_object: SceneObjectInterface;

    constructor(scene_object: SceneObjectInterface) {
        this.scene_object = scene_object;
    }
    get sceneObject() {
        return this.scene_object;
    }
    get application() {
        return this.scene_object.application;
    }

    getVideoStemFileInfoFor(item: AudioVisualPlaybackQueueItem) {
        return item.getVideoStemFileInfo(this.scene_object);
    }

    pushItem(item: AudioVisualPlaybackQueueItem) {
        let fileinfo = item.getVideoStemFileInfo(this.scene_object);
        let length = 0;
        if (!fileinfo || fileinfo.duration === undefined) {
            console.warn(
                `AudioVisualPlaybackQueue::pushItem missing fileinfo duration ${item.videoStem}`,
            );
        } else {
            length = TimeUtils.secondsToWholeMilliseconds(fileinfo.duration);
        }

        return this.item_queue.appendLengthWithAssociatedObject(length, item);
    }

    insertItemAt(item: AudioVisualPlaybackQueueItem, startAt: number) {
        let fileinfo = item.getVideoStemFileInfo(this.scene_object);
        if (!fileinfo || fileinfo.duration === undefined) {
            console.warn(
                `AudioVisualPlaybackQueue::pushItem missing fileinfo duration ${item.videoStem}`,
            );
            return undefined;
        }
        let length = TimeUtils.secondsToWholeMilliseconds(fileinfo.duration);

        return this.item_queue.insertRange(new Range(startAt, startAt + length), item);
    }

    insertItemWithRange(item: AudioVisualPlaybackQueueItem, timeRange: Range) {
        return this.item_queue.insertRange(timeRange, item);
    }

    clearQueueExceptStarted() {
        this.item_queue.deleteItemsUnlessAssociatedObject((each: any) => each.isStarted);
    }

    clearQueue(isStopPlayingItem = true) {
        if (isStopPlayingItem) {
            let found = this.getPlayingItem();
            if (found != null) {
                found.stop(this.scene_object);
            }
        }

        this.item_queue.clear();
    }

    cleanupItems() {
        this.item_queue.deleteItemsBeforeFirstAssociatedObjectFoundWhere(
            (each: any) => each.isStarted,
        );
    }
    get isError() {
        let first = this.item_queue.getFirstAssociatedObject();
        let firstElement = first?.loading.visual_element?.firstResourceCanvasElement();
        return firstElement?.isError;
    }
    get error() {
        let first = this.item_queue.getFirstAssociatedObject();
        let firstElement = first?.loading.visual_element?.firstResourceCanvasElement();
        return firstElement?.error;
    }
    beginLoadingStartOfQueue(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        let first = this.item_queue.getFirstAssociatedObject();
        if (first != null) {
            first.start_loading(this.scene_object, gettingReadyCallbackCollector);

            let callback_collector_promise = gettingReadyCallbackCollector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promises = this.callback_collector_promises.filter(
                        (item) => item !== callback_collector_promise,
                    );
                });
            this.callback_collector_promises.push(callback_collector_promise);
        }
    }

    beginPlayingStartOfLoadedQueue() {
        let first = this.item_queue.getFirstAssociatedObject();
        if (first != null) {
            first.start(this);
        }
    }

    beginPlayingStartOfQueue(gettingReadyCallbackCollector: GettingReadyCallbackCollector) {
        let first = this.item_queue.getFirstAssociatedObject();
        if (first != null) {
            first.is_play_immediate = true;
            first.start_loading(this.scene_object, gettingReadyCallbackCollector);

            let callback_collector_promise = gettingReadyCallbackCollector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promises = this.callback_collector_promises.filter(
                        (item) => item !== callback_collector_promise,
                    );

                    first.start(this);

                    let next = this.item_queue.getNextAssociatedObject(first);
                    if (next) {
                        let next_gettingReadyCallbackCollector =
                            new GettingReadyCallbackCollector();
                        next.start_loading(this.scene_object, next_gettingReadyCallbackCollector);
                    }
                });
            this.callback_collector_promises.push(callback_collector_promise);
        }
    }

    beginPlayingItemAfter(item: AudioVisualPlaybackQueueItem, isImmidiate = false) {
        let next = this.item_queue.getNextAssociatedObject(item);
        if (next != null) {
            let gettingReadyCallbackCollector = new GettingReadyCallbackCollector();

            next.start_loading(this.scene_object, gettingReadyCallbackCollector);

            let callback_collector_promise = gettingReadyCallbackCollector
                .newWaitPromiseForAllCallbacks()
                .then((result) => {
                    this.callback_collector_promises = this.callback_collector_promises.filter(
                        (item) => item !== callback_collector_promise,
                    );

                    let playing = this.item_queue.firstAssociatedObjectWhere(
                        (each: AudioVisualPlaybackQueueItem) => each.isStarted === true,
                    );
                    if (playing != null) {
                        playing.stop(this.scene_object, next.resourceCanvasElement);
                    }

                    next.start(this);

                    let after_next = this.item_queue.getNextAssociatedObject(next);
                    if (after_next) {
                        let next_gettingReadyCallbackCollector =
                            new GettingReadyCallbackCollector();
                        after_next.start_loading(
                            this.scene_object,
                            next_gettingReadyCallbackCollector,
                        );
                    }
                });

            this.callback_collector_promises.push(callback_collector_promise);
        }
    }

    getPlayingItem(): AudioVisualPlaybackQueueItem | null {
        return this.item_queue.firstAssociatedObjectWhere((each: AudioVisualPlaybackQueueItem) => {
            return each.isStarted === true;
        });
    }

    stop(next_resource_canvas_element: ResourceCanvasElementInterface | undefined) {
        let found = this.getPlayingItem();
        if (found != null) {
            found.stop(this.scene_object, next_resource_canvas_element);
        }
    }

    getEndOfLastItem(): number | null {
        return this.item_queue.getEndOfLastRange();
    }
    // isEmpty(){
    //     return this.item_queue.
    // }
}
