import { ImageCanvasElement } from "../canvasElements/imageCanvasElement";
import { ResourceCanvasElement } from "../resourceCanvasElements/ResourceCanvasElement";
import { ResourceCanvasElementInterface } from "../resourceCanvasElements/ResourceCanvasElementInterface";
import { WebApplication } from "../../../webApplication";
import { FileResourceRequest } from "../../../resources/FileResourceRequest";

import { FileResource } from "../../../resources/FileResource";
import { VisualElement } from "../visualElement";
import { ResourceInterface } from "./resourceInterface";
import { LocalServerFileCache } from "../../../LocalServerFileCache";

let c2 = require("c2.js");

export class ImageResource extends FileResource implements ResourceInterface {
    static errrorImage: string;
    static webp_extension = ".webp";
    static png_extension = ".png";
    static svg_extension = ".svg";
    static webp_category = "visuals/images/webp/";
    static png_category = "visuals/images/png/";
    static svg_category = "visuals/images/svg/";
    static json_name = "image";

    static canCreateResourceFromJson(json: any, property: string) {
        return property === "image" && json[property] !== undefined;
    }

    static canCreateResourceFromJsonObject(json: any) {
        return json["image"] !== undefined;
    }

    static requestResource(name: string, path: string, path2: string, webapp: WebApplication) {
        let request = new FileResourceRequest(
            path,
            name,
            "",
            "",
            path2,
            true,
            false,
            false,
            webapp,
        );

        let resource;

        let webp_enabled = webapp.getSetting(WebApplication.IsWebpEnabledSettingName);
        let webp_image_path = request.fullpathWithExtension(
            ImageResource.webp_extension,
            ImageResource.webp_category,
        );
        let svg_image_path = request.fullpathWithExtension(
            ImageResource.svg_extension,
            ImageResource.svg_category,
        );
        let webp_image_asset = webapp.server.server_file_cache.findFile(webp_image_path);
        let svg_image_asset = webapp.server.server_file_cache.findFile(svg_image_path);

        // if (svg_image_asset) {
        //   request.setExtension(ImageResource.svg_extension);
        //   request.setCategoryPath(ImageResource.svg_category);
        //   resource = new SvgImageResource();
        // }
        // else
        if (webp_enabled && webp_image_asset) {
            request.setExtension(ImageResource.webp_extension);
            request.setCategoryPath(ImageResource.webp_category);

            if (webapp.getSetting(WebApplication.IsWebpWebAssemblyEnabledSettingName)) {
                resource = new WebPWebAssemblyImageResource();
            } else {
                resource = new WebPImageResource();
            }
        } else {
            request.setExtension(ImageResource.png_extension);
            request.setCategoryPath(ImageResource.png_category);
            resource = new PngImageResource();
        }

        if (resource) {
            resource.resource_request = request;
            resource.url = resource.resource_request.toUrlPath();
            resource.server_file_cache = webapp.server.server_file_cache;
            resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
            resource.type = "image";
        }

        return resource;
    }

    static CopyImageJsonToJsonProperties(json: any) {
        let result: any = {};

        let key = "image";
        let key_dot = key + ".";

        for (let each in json) {
            if (each === key) {
                result["name"] = json[each];
            } else if (each.startsWith(key_dot)) {
                let new_key = each.slice(key_dot.length);
                result[new_key] = json[each];
            }
        }
        return result;
    }

    static CopyImageJson(
        json: any,
        replace_key_name?: string,
        replaced_name_prefix?: string,
        replaced_name_suffix?: string,
    ) {
        let result: any = {};

        let replace_key_name_dot = replace_key_name + ".";
        let key = "image";
        let key_dot = key + ".";

        for (let each in json) {
            if (replace_key_name) {
                if (each === replace_key_name) {
                    result[key] = replaced_name_prefix
                        ? replaced_name_prefix + json[each] + (replaced_name_suffix ?? "")
                        : json[each];
                } else if (each.startsWith(replace_key_name_dot)) {
                    let new_key = key_dot + each.slice(replace_key_name_dot.length);
                    result[new_key] = json[each];
                }
            } else {
                if (each === key || each.startsWith(key_dot)) {
                    result[each] = json[each];
                }
            }
        }

        return result;
    }

    static createResourceFromJson(
        json: any,
        property: string,
        path: string,
        path2: string,
        webapp: WebApplication,
    ) {
        if (this.canCreateResourceFromJson(json, property)) {
            let result = this.requestResource(json.image, path, path2, webapp);
            if (result) {
                result.centerRotateDegrees = json["image.rotate"];
                result.scale = json["image.scale"];
                result.corner_radius = json["image.corner_radius"];
                result.json_properties = ImageResource.CopyImageJsonToJsonProperties(json);
                return result;
            }
        }
    }

    resource_element?: HTMLImageElement;
    resource_request: FileResourceRequest;
    is_loading = false;
    isError: boolean;
    error: string;
    isLoaded: boolean;
    json_properties: any;
    centerRotateDegrees: number;
    scale: number;
    corner_radius: number;
    onVisualLoaded: ResourceInterface["onVisualLoaded"];

    constructor() {
        super();
    }

    checkForErrors() {
        return false;
    }

    createResourceCanvasElement(vis: VisualElement) {
        return new ResourceCanvasElement(vis, this);
    }

    notifyError() {
        if (!this.isError) {
            this.isError = true;
        }
    }

    toSourceURLName() {
        return this.resource_request.name;
    }

    start_loading(
        server_file_cache: LocalServerFileCache,
        resource_canvas_element: ResourceCanvasElementInterface,
    ) {
        this.is_loading = true;
        let found = this.server_file_cache.TryGetHTMLElementFromCache(this.url) as HTMLImageElement;

        if (found) {
            this.onImageLoaded(found);
        } else {
            let image = new Image();

            image.addEventListener("load", () => {
                this.onImageLoaded(image);
                server_file_cache.AddHTMLElementToCache(this.url, image);
            });

            image.addEventListener("error", (event) => {
                this.onImageLoadError(image, event);
            });

            image.src = this.url;
        }
    }

    onImageLoaded(htmlImageElement: HTMLImageElement) {
        this.resource_element = htmlImageElement;
        this.is_loading = false;
        this.isLoaded = true;
        this.isError = false;
        this.onVisualLoaded?.(this);
    }

    onImageLoadError(htmlImageElement: HTMLImageElement, ev: ErrorEvent) {
        this.resource_element = undefined;
        this.is_loading = false;
        this.isLoaded = true;
        this.isError = true;
        this.error = "image resource error" + " : " + this.url;
        console.warn(this.error);
        this.onVisualLoaded?.(this);
    }

    initialize() {}

    start(resource_canvas_element: ResourceCanvasElementInterface) {}

    stop() {}

    isLoading() {
        return this.is_loading;
    }

    // /**
    //  *
    //  * @returns {Promise|undefined}
    //  */
    // getLoadingPromise() {
    //   return this.loading_promise;
    // }

    pixel_size() {
        return [this.imageWidth(), this.imageHeight()];
    }

    imageWidth() {
        let result = 0;

        if (this.isError) {
            result = 1920;
        } else if (this.resource_element && this.resource_element.width !== undefined) {
            result = this.resource_element.width;
        } else if (this.url_file_info && this.url_file_info.width !== undefined) {
            result = this.url_file_info.width;
        }

        // var scaler = this.scale == undefined ? 1 : this.scale;
        // result *= scaler;

        return result;
    }

    imageHeight() {
        let result = 0;

        if (this.isError) {
            result = 1080;
        } else if (this.resource_element && this.resource_element.height !== undefined) {
            result = this.resource_element.height;
        } else if (this.url_file_info && this.url_file_info.height !== undefined) {
            result = this.url_file_info.height;
        }

        // var scaler = this.scale == undefined ? 1 : this.scale;
        // result *= scaler;

        return result;
    }

    createCanvasElement(resource_canvas_element: ResourceCanvasElementInterface) {
        if (!this.resource_element) {
            return undefined;
        }
        return new ImageCanvasElement(this, this.resource_element, resource_canvas_element);
    }
}

export class ImageElementImageResource extends ImageResource {
    constructor() {
        super();
    }
}

export class PngImageResource extends ImageElementImageResource {
    constructor() {
        super();
    }
}

export class WebPImageResource extends ImageElementImageResource {
    constructor() {
        super();
    }
}

export class WebPWebAssemblyImageResource extends ImageResource {
    constructor() {
        super();
    }
}

export class SvgImageResource extends ImageResource {
    constructor() {
        super();
    }
}
