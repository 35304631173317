import { ResourceCanvasElementInterface } from "../resourceCanvasElements/ResourceCanvasElementInterface.js";
import { ResourceInterface } from "../resources/resourceInterface";
import { CanvasElement } from "./CanvasElement.js";

export class HTML5CanvasElement extends CanvasElement {
    element: HTMLVideoElement | HTMLImageElement;

    /**
     *
     * @param {import('../resources/resourceInterface').ResourceInterface|undefined} resource
     * @param {HTMLMediaElement|HTMLImageElement} element
     * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} resource_canvas_element
     * @param {boolean} is_invalidating_draw
     */
    constructor(
        resource: ResourceInterface | undefined,
        element: HTMLVideoElement | HTMLImageElement,
        resource_canvas_element: ResourceCanvasElementInterface | undefined,
        is_invalidating_draw = true,
    ) {
        super(resource, resource_canvas_element, is_invalidating_draw);
        this.element = element;
    }

    toSourceURL() {
        return this.element.currentSrc;
    }
}
