import { FileInfoType, LocalServerFileCache } from "../../LocalServerFileCache";
import { Resources } from "../../resources";
import { SceneGraphAudioVisualScripts } from "../../sceneGraph/audio-visual-script/SceneGraphAudioVisualScripts";
import { SceneGraphAudioVisualStateSet } from "../../sceneGraph/audio-visual-script/SceneGraphAudioVisualStateSet";
import { WebApplication } from "../../webApplication";
import { AudioVisualScriptResourceCanvasElement } from "../visual/resourceCanvasElements/AudioVisualScriptResourceCanvasElement";
import { ResourceCanvasElementInterface } from "../visual/resourceCanvasElements/ResourceCanvasElementInterface";
import { VisualElement } from "../visual/visualElement";

export class AudioVisualScriptResource {
    static canCreateResourceFromJson(json: any, property: string) {
        return property === "audio_visual_script" && json[property] !== undefined;
    }
    static canCreateResourceFromJsonObject(json: any) {
        return json["audio_visual_script"] !== undefined;
    }
    static createResourceFromJson(
        json: any,
        property: string,
        path: string,
        path2: string,
        webapp: WebApplication,
    ) {
        let result = new AudioVisualScriptResource();

        result.json_name = json[property].name + "-audio_visual_script";
        let file_path = webapp.resources.getDataFilePathByNameAndExtension(
            result.json_name,
            Resources.jsonExtension,
        );

        result.video_base_name = json[property].video_base_name;
        result.audio_base_name = json[property].audio_base_name;

        result.json = webapp.resources.getFetchedJson(file_path);

        result.default_state = json[property].default_state;

        result.state_set = new SceneGraphAudioVisualStateSet(result.json["states"], result);
        result.state_set.initializeFromJson();

        result.scripts = new SceneGraphAudioVisualScripts(result.json["scripts"], result);
        result.scripts.initializeFromJson();

        return result;
    }
    json_name: string;
    json: any;
    video_base_name?: string;
    audio_base_name?: string;
    default_state?: string;
    state_set: SceneGraphAudioVisualStateSet;
    scripts: SceneGraphAudioVisualScripts;
    isLoaded: boolean;
    isError: boolean;
    url_file_info?: FileInfoType;

    createResourceCanvasElement(vis: VisualElement) {
        return new AudioVisualScriptResourceCanvasElement(vis, this);
    }

    pixel_size() {
        return [0, 0];
    }

    isLoading() {
        return false;
    }

    cancelLoading() {}

    checkForErrors() {
        return false;
    }

    start_loading(
        server_file_cache: LocalServerFileCache,
        resource_canvas_element: ResourceCanvasElementInterface,
    ) {}

    start(resource_canvas_element: ResourceCanvasElementInterface) {}
    /**
     *
     */
    stop() {}

    createCanvasElement(resource_canvas_element: ResourceCanvasElementInterface) {
        throw "invalid operation";
    }

    notifyError() {
        if (!this.isError) {
            this.isError = true;
        }
    }

    getLoadingPromise() {
        return undefined;
    }
}
